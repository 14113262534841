exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-asset-js": () => import("./../../../src/pages/asset.js" /* webpackChunkName: "component---src-pages-asset-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-microcms-news-news-id-js": () => import("./../../../src/pages/news/{microcmsNews.newsId}.js" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-js" */),
  "component---src-pages-news-news-summary-js": () => import("./../../../src/pages/news/news-summary.js" /* webpackChunkName: "component---src-pages-news-news-summary-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-site-policy-js": () => import("./../../../src/pages/site-policy.js" /* webpackChunkName: "component---src-pages-site-policy-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

